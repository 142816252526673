// Override default variables before the import
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,900;1,900&display=swap');

$body-bg: #FFFFFFF;
$light-grey: #F2F2F2;

.hero {
    min-height: 60vh;
    background-color: $body-bg;
    padding-bottom:50px;
}

.hero .content {
    margin-top: 10vh;
    text-align: center;
}

.hero .content img.animation {
    // background-color:#FF0000;
}

.hero .content img.logo {
    margin-top:70px;
}

.whatwedo {
    min-height:60vh;
    padding:100px;
}
.weworkfor {
    background-color: 
    $body-bg;
}

.weworkfor .backgroundBand {
    margin-top:30px;
    height:32px;
}

.weworkfor .pattern {
    background-image: url('./assets/images/straight_zephyr.png');
    background-repeat: cover;
    background-size: auto;
}

.weworkfor .content h1 {
    text-align:center;
    padding: 70px;
}

.weworkfor .content p {
    padding:100px;
    padding-top:0px;

}

h1 {
    font-family: 'Nunito Sans', sans-serif;
    text-transform: uppercase;
    font-size: 7rem !important;
    line-height: 0.9 !important;
    font-style: italic;
}

p {
    line-height: 1.9 !important;

}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';